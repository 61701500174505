import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {

    const { logout } = useAuth0();

    return (
        <button className="login_form__logout w-button" onClick={ () => logout() }>
            Log Out
        </button>
    )
}

export default LogoutButton
